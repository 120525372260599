:root {
  --access-code-header-font-size: 13px;
  --access-code-input-font-size: 11px;
  --access-code-input-line-height: 2;
  --access-code-input-padding: 8px 4px;
  --access-code-refresh-button-margin: 0;
  --access-code-teacher-list-item-font-size: 13px;
  --access-code-teacher-list-label-font-size: 14px;
  --access-code-teacher-x-icon-height: 100%;
  --accommodations-table-container-max-width: 1366px;
  --accommodations-table-header-cell-default-text-align: center;
  --accommodations-table-header-cell-username-width: 175px;
  --accommodations-table-header-section-label-white-space: normal;
  --accommodations-table-top-header-section-label-max-width: auto;
  --add-admin-coteacher-button-display: block;
  --add-admin-coteacher-container-padding: 15px;
  --add-button-new-teacher-display: block;
  --add-join-class-modal-actions-btn-font-size: 13px;
  --add-join-class-modal-actions-btn-font-weight: normal;
  --add-join-class-modal-actions-height: 59px;
  --add-join-class-modal-header-height: 59px;
  --add-library-course-to-classrooms-button-bg-color-hover: var(--report-download-button-bg-color-hover);
  --add-library-course-to-classrooms-button-bg-color: var(--report-download-button-bg-color);
  --add-library-course-to-classrooms-button-border-color: var(--report-download-button-border-color);
  --add-library-course-to-classrooms-button-color: var(--report-download-button-color);
  --add-library-course-to-classrooms-button-font-size: var(--report-download-button-font-size);
  --add-library-course-to-classrooms-button-font-weight: var(--report-download-button-font-weight);
  --add-library-course-to-classrooms-button-min-height: var(--report-download-button-min-height);
  --add-library-course-to-classrooms-button-min-width: 110px;
  --add-library-course-to-classrooms-button-padding: 1px 0 0 0;
  --add-library-course-to-classrooms-modal-body-header-label-color: #000;
  --add-library-course-to-classrooms-modal-body-header-label-font-size: 14px;
  --add-library-course-to-classrooms-modal-body-header-label-font-weight: normal;
  --add-library-course-to-classrooms-modal-body-padding: 17px 40px 3px 40px;
  --add-library-course-to-classrooms-modal-checkbox-bg-color: var(--checkbox-bg-color);
  --add-library-course-to-classrooms-modal-faculty-option-label-disabled-font-color: #9d9d9d;
  --add-library-course-to-classrooms-modal-faculty-option-label-disabled-font-weight: normal;
  --add-library-course-to-classrooms-modal-faculty-option-label-font-size: 14px;
  --add-library-course-to-classrooms-modal-faculty-option-label-popup-font-size: 14px;
  --add-library-course-to-classrooms-modal-faculty-options-padding: 0 30px;
  --admin-classrooms-filtered-header-table-cell-button-line-height: 0.36;
  --admin-classrooms-view-table-border-radius: 4px;
  --admin-classrooms-view-table-min-width: 1419px;
  --admin-users-filtered-header-table-cell-button-padding: var(--theme-button-default-padding);
  --aria-skip-link-background: #fff;
  --aria-skip-link-border-radius: 4px;
  --aria-skip-link-border: 2px solid var(--outline-color);
  --aria-skip-link-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --aria-skip-link-color: #0066cc;
  --aria-skip-link-font-size: var(--theme-font-size);
  --aria-skip-link-padding: 8px;
  --aria-skip-link-text-decoration: underline;
  --aria-skip-link-transform: translate(1px, 7px);
  --aria-skip-link-transition: opacity 0.2s ease, transform 0.2s ease;
  --assignment-active-tab-nav-background-color: #707070;
  --assignment-active-tab-nav-color: #fff;
  --assignment-active-tab-nav-hover-color: #7b7b7b;
  --assignment-button-show-all-background-color: var(--assignment-tab-nav-background-color);
  --assignment-button-show-all-color: var(--assignment-tab-nav-color);
  --assignment-button-show-all-font-size: var(--assignment-tab-nav-font-size);
  --assignment-button-show-all-font-weight: var(--assignment-tab-nav-font-weight);
  --assignment-button-show-all-height: 40px;
  --assignment-button-show-all-hover-color: var(--assignment-tab-nav-hover-color);
  --assignment-button-show-all-margin: 0 5px 0 0;
  --assignment-button-show-all-padding: 2px 0 0 0;
  --assignment-button-show-all-width: 103.52px;
  --assignment-card-button-basic-primary-bg-color-hover: var(--assignment-card-button-basic-primary-bg-color);
  --assignment-card-button-basic-primary-bg-color: var(--theme-primary-button-bg-color2);
  --assignment-card-button-basic-primary-border-hover: solid var(--theme-button-default-border-size) var(--theme-basic-button-border-hover-color);
  --assignment-card-button-basic-primary-border: solid var(--theme-button-default-border-size) var(--theme-basic-button-border-color);
  --assignment-card-button-basic-primary-font-color-hover: var(--theme-basic-button-hover-color);
  --assignment-card-button-basic-primary-font-color: var(--theme-basic-button-color);
  --assignment-card-button-padding: 1px 0 0 0;
  --assignment-card-header-title-color: var(--theme-header-color);
  --assignment-card-header-title-font-size: 18px;
  --assignment-card-header-title-font-weight: 700;
  --assignment-card-spacer-color: #000;
  --assignment-card-spacer-font-weight: normal;
  --assignment-card-subtitle-font-size: var(--theme-font-size);
  --assignment-content-name-search-height: 40px;
  --assignment-content-name-search-padding: 0;
  --assignment-content-name-search-width: 330px;
  --assignment-tab-menu-active-border-inner-bottom: none;
  --assignment-tab-menu-active-border-inner-top: none;
  --assignment-tab-menu-border-inner-height: 38px;
  --assignment-tab-menu-border-inner-last-radius: 0 4px 4px 0;
  --assignment-tab-menu-border-inner: var(--assignment-tab-menu-border);
  --assignment-tab-menu-border: 1px solid #dedfdf;
  --assignment-tab-menu-control-padding: 2px 0 0 0;
  --assignment-tab-menu-list-border-inner-height: 40px;
  --assignment-tab-menu-margin: 0 5px 0 2px;
  --assignment-tab-nav-background-color: #fff;
  --assignment-tab-nav-border-right: 2px solid #efefef;
  --assignment-tab-nav-color: #626262;
  --assignment-tab-nav-font-size: var(--theme-font-size);
  --assignment-tab-nav-font-weight: bold;
  --assignment-tab-nav-hover-color: #fafafa;
  --assignment-tab-nav-padding-bottom: 4px;
  --assignment-table-container-font-size: 12px;
  --assignment-table-paginator-font-size: 12px;
  --border-radius: 4px;
  --box-shadow-color: #262228;
  --breadcrumb-chevron-font-size: inherit;
  --breadcrumb-chevron-font-weight: inherit;
  --breadcrumb-chevron-foreground-color: var(--breadcrumb-foreground-color);
  --breadcrumb-chevron-opacity: inherit;
  --breadcrumb-foreground-color-clickable-hover: var(--theme-link-color-directly-on-background-hover);
  --breadcrumb-foreground-color-clickable: var(--theme-link-color-directly-on-background);
  --breadcrumb-foreground-color: var(--theme-link-color-directly-on-background);
  --breadcrumb-section-font-size-clickable: var(--breadcrumb-section-font-size);
  --breadcrumb-section-font-size: 13px;
  --breadcrumb-section-font-weight-clickable: 500;
  --breadcrumb-section-font-weight: 600;
  --breadcrumb-section-margin: 6px 0 0 0;
  --breadcrumb-section-text-decoration-clickable-hover: underline;
  --breadcrumb-section-text-decoration-clickable: underline;
  --breadcrumb-wrapper-color: var(--theme-background-header-color);
  --breadcrumb-wrapper-padding: 10px 40px;
  --button-bar-border-radius: 4px;
  --button-bar-btn-bg-color-hover: var(--assignment-tab-nav-hover-color);
  --button-bar-btn-bg-color: var(--assignment-tab-nav-background-color);
  --button-bar-btn-color-hover: var(--button-bar-btn-color);
  --button-bar-btn-color: var(--assignment-tab-nav-color);
  --button-bar-btn-font-size: var(--assignment-tab-nav-font-size);
  --button-bar-btn-font-weight:  var(--assignment-tab-nav-font-weight);
  --button-bar-btn-margin: 0;
  --button-bar-btn-min-height: 33px;
  --button-bar-btn-min-width: 150px;
  --button-bar-btn-padding: inherit;
  --button-bar-btn-selected-bg-color-hover: var(--assignment-active-tab-nav-hover-color);
  --button-bar-btn-selected-bg-color: var(--assignment-active-tab-nav-background-color);
  --button-bar-btn-selected-color-hover: var(--button-bar-btn-selected-color);
  --button-bar-btn-selected-color: var(--assignment-active-tab-nav-color);
  --button-bar-container-border-radius: 0;
  --button-bar-container-border: none;
  --card-background-color-student: var(--card-background-color);
  --card-background-color: #3e7999;
  --card-border-color: #dadadb;
  --card-content-background-color: white;
  --card-content-color: rgba(0, 0, 0, 0.87);
  --card-default-lesson-color-student: var(--card-default-lesson-color);
  --card-default-lesson-color: #7ebf55;
  --card-height: 256px;
  --card-secondary-border-color: #cfcfcf;
  --card-tag1-background-color: #468847;
  --card-tag2-background-color: #e3b700;
  --card-width: 293px;
  --caution-banner-nav-height: var(--nav-height);
  --caution-banner-nav-separator-height: var(--nav-separator-height);
  --caution-banner-text-minimized-max-height: 27px;
  --caution-banner-with-impersonation-banner-bound-bottom-offset-top: 102px;
  --checkbox-bg-color: #0083a5;
  --checkbox-checkmark-color: #fff;
  --checkbox-checkmark-font-size: 13px;
  --checkbox-checkmark-font-weight: 600;
  --checkbox-checkmark-right: 4px;
  --checkbox-checkmark-top: -2px;
  --classroom-card-image-default-background: var(--card-background-color);
  --classroom-card-image-default-border-radius: 4px 0 0 4px;
  --classroom-card-image-default-padding: 33px 40px;
  --classroom-card-image-height: 126px;
  --classroom-card-image-nondefault-border-radius: 4px 0 0 4px;
  --classroom-card-image-nondefault-height: 126px;
  --classroom-card-image-nondefault-width: 154px;
  --classroom-card-image-width: 154px;
  --classroom-card-image-wrapper-margin: inherit;
  --classroom-card-image-wrapper-padding: 0;
  --classroom-card-label-courses-font-size: var(--classroom-card-label-font-size);
  --classroom-card-label-courses-font-weight: var(--classroom-card-label-font-weight);
  --classroom-card-label-font-size: 14px;
  --classroom-card-label-font-weight: normal;
  --classroom-card-nav-button-icon-margin-accommodations: var(--classroom-card-nav-button-icon-margin-default);
  --classroom-card-nav-button-icon-margin-assignment: var(--classroom-card-nav-button-icon-margin-default);
  --classroom-card-nav-button-icon-margin-course: var(--classroom-card-nav-button-icon-margin-default);
  --classroom-card-nav-button-icon-margin-default: 0 5px 0 0;
  --classroom-card-nav-button-icon-margin-groups: var(--classroom-card-nav-button-icon-margin-default);
  --classroom-card-nav-button-icon-margin-roster: var(--classroom-card-nav-button-icon-margin-default);
  --classroom-card-nav-button-icon-margin-settings: var(--classroom-card-nav-button-icon-margin-default);
  --classroom-card-nav-button-icon-margin-workspace: var(--classroom-card-nav-button-icon-margin-default);
  --classroom-card-nav-text-font-size: var(--theme-font-size);
  --classroom-card-nav-text-font-weight: normal;
  --classroom-card-nav-text-link-color: var(--theme-link-color);
  --classroom-card-nav-text-margin: 4px 15px 0 0;
  --classroom-card-status-text-courses-font-size: var(--classroom-card-status-text-font-size);
  --classroom-card-status-text-courses-font-weight: var(--classroom-card-status-text-font-weight);
  --classroom-card-status-text-font-size: var(--theme-font-size);
  --classroom-card-status-text-font-weight: normal;
  --classroom-card-status-text-link-color: var(--theme-link-color);
  --classroom-card-title-font-size: 18px;
  --classroom-card-title-font-weight: bold;
  --classroom-card-title-line-height: inherit;
  --co-teacher-access-code-display: block;
  --color-key-card-admin-classrooms-font-size: 11px;
  --color-key-card-admin-classrooms-iconKeys-padding: 4px 0 3px 0;
  --color-key-card-admin-classrooms-iconKeys-square-padding: 1px 0 0 0;
  --color-key-card-gradebook-margin: 8px 0 0 0;
  --color-key-card-gradebook-observational-iconkey-padding: 3px 12px;
  --color-key-card-gradebook-observational-width: 396px;
  --color-key-card-report-theme-font-size: var(--report-theme-font-size);
  --controlled-carousel-container-border-radius-bottom-left: 4px;
  --controlled-carousel-container-border-radius-bottom-right: 4px;
  --controlled-carousel-container-border-radius-top-left: 4px;
  --controlled-carousel-container-border-radius-top-right: 4px;
  --controlled-carousel-container-border-radius: 4px;
  --course-card-basic-button-border-color: var(--course-card-basic-button-color);
  --course-card-basic-button-border-hover-color: var(--course-card-basic-button-hover-color);
  --course-card-basic-button-color: var(--theme-primary-button-bg-color);
  --course-card-basic-button-font-size: var(--theme-secondary-font-size);
  --course-card-basic-button-hover-color: var(--theme-primary-button-hover-color);
  --course-card-basic-button-hover-weight: 600;
  --course-card-basic-button-padding: 2px 0 0 0;
  --course-card-button-basic-primary-bg-color-hover: var(--course-card-button-basic-primary-bg-color);
  --course-card-button-basic-primary-bg-color: var(--theme-primary-button-bg-color2);
  --course-card-button-basic-primary-border-hover: solid var(--theme-button-default-border-size) var(--theme-basic-button-border-hover-color);
  --course-card-button-basic-primary-border: solid var(--theme-button-default-border-size) var(--theme-basic-button-border-color);
  --course-card-button-basic-primary-font-color-hover: var(--theme-basic-button-hover-color);
  --course-card-button-basic-primary-font-color: var(--theme-basic-button-color);
  --course-card-button-font-size: var(--theme-secondary-font-size);
  --course-card-button-font-weight: 600;
  --course-card-button-large-max-height: var(--course-card-button-max-height);
  --course-card-button-large-max-width: var(--course-card-button-max-width);
  --course-card-button-large-min-height: var(--course-card-button-min-height);
  --course-card-button-large-min-width: var(--course-card-button-min-width);
  --course-card-button-max-height: 26px;
  --course-card-button-min-height: 26px;
  --course-card-button-min-width: 88px;
  --course-card-primary-button-bg-color: var(--theme-primary-button-bg-color);
  --course-card-primary-button-color: var(--theme-primary-button-color);
  --course-card-primary-button-font-hover-color: var(--course-card-primary-button-color);
  --course-card-primary-button-font-size: var(--theme-secondary-font-size);
  --course-card-primary-button-font-weight: 600;
  --course-card-primary-button-hover-color: var(--theme-primary-button-hover-color);
  --course-card-primary-button-padding: 2px 0 0 0;
  --course-listing-background-color: #80ba44;
  --course-listing-banner-height: 40px;
  --course-listing-banner-padding: var(--theme-button-default-padding);
  --course-listing-class-tab-border-color: #d4d4d5;
  --course-quick-nav-button-border-radius: var(--border-radius);
  --course-quick-nav-button-font-size: var(--theme-font-size);
  --course-quick-nav-button-font-weight: normal;
  --course-quick-nav-button-line-height: 14px;
  --course-quick-nav-button-margin: 0px 2px 0px 7px;
  --course-quick-nav-close-button-bg-color-hover: var(--theme-primary-button-hover-color);
  --course-quick-nav-close-button-bg-color: var(--theme-primary-button-bg-color);
  --course-quick-nav-close-button-color-hover: #fff;
  --course-quick-nav-close-button-color: #fff;
  --course-quick-nav-open-button-bg-color-hover: var(--theme-primary-button-hover-color);
  --course-quick-nav-open-button-bg-color: var(--theme-primary-button-bg-color);
  --course-quick-nav-open-button-color-hover: #fff;
  --course-quick-nav-open-button-color: #fff;
  --course-ribbon-carousel-arrow-bg-color-hover: #aaef8d;
  --course-ribbon-carousel-arrow-bg-color: #99ed77;
  --course-ribbon-carousel-arrow-border-color-hover: #141414;
  --course-ribbon-carousel-arrow-border-color: #000;
  --course-ribbon-carousel-arrow-border-width: 0 2px 2px 0;
  --course-ribbon-carousel-arrow-height: 20px;
  --course-ribbon-carousel-arrow-next-after-transform: translate(-64%, -50%) rotate(-45deg);
  --course-ribbon-carousel-arrow-next-right: -50px;
  --course-ribbon-carousel-arrow-prev-after-transform: translate(-30%, -50%) rotate(135deg);
  --course-ribbon-carousel-arrow-prev-left: -50px;
  --course-ribbon-carousel-arrow-width: 20px;
  --course-ribbon-carousel-card-section-bg-color-hover: var(--course-ribbon-carousel-card-section-bg-color);
  --course-ribbon-carousel-card-section-bg-color-selected: #99ed77;
  --course-ribbon-carousel-card-section-bg-color: #fff;
  --course-ribbon-carousel-card-section-border-hover: var(--course-ribbon-carousel-card-section-border);
  --course-ribbon-carousel-card-section-border-selected: 1px solid #dedfdf;
  --course-ribbon-carousel-card-section-border: 1px solid #dedfdf;
  --course-ribbon-carousel-card-section-description-font-color-hover: rgba(0, 0, 0, 0.87);
  --course-ribbon-carousel-card-section-description-font-color-selected: #000;
  --course-ribbon-carousel-card-section-description-font-color: #000;
  --course-ribbon-carousel-card-section-description-font-size: 12px;
  --course-ribbon-carousel-card-section-description-font-weight-hover: normal;
  --course-ribbon-carousel-card-section-description-font-weight-selected: normal;
  --course-ribbon-carousel-card-section-description-font-weight: normal;
  --course-ribbon-carousel-card-section-description-height: 18px;
  --course-ribbon-carousel-card-section-description-width: 123px;
  --course-ribbon-carousel-card-section-title-font-color-hover: rgba(0, 0, 0, 0.87);
  --course-ribbon-carousel-card-section-title-font-color-selected: #000;
  --course-ribbon-carousel-card-section-title-font-color: #000;
  --course-ribbon-carousel-card-section-title-font-size: 13px;
  --course-ribbon-carousel-card-section-title-font-weight-hover: bold;
  --course-ribbon-carousel-card-section-title-font-weight-selected: bold;
  --course-ribbon-carousel-card-section-title-font-weight: bold;
  --course-ribbon-carousel-card-section-title-height: 18px;
  --course-ribbon-carousel-card-section-title-width: 123px;
  --course-ribbon-carousel-card-section-width: 150px;
  --course-ribbon-carousel-card-subsection-bg-color-hover: #d3d3dc;
  --course-ribbon-carousel-card-subsection-bg-color-selected: var(--course-ribbon-carousel-card-section-bg-color-selected);
  --course-ribbon-carousel-card-subsection-bg-color: var(--course-ribbon-carousel-card-section-bg-color);
  --course-ribbon-carousel-card-subsection-border-hover: 2px solid #484848;
  --course-ribbon-carousel-card-subsection-border-selected: var(--course-ribbon-carousel-card-section-border-selected);
  --course-ribbon-carousel-card-subsection-border: var(--course-ribbon-carousel-card-section-border);
  --course-ribbon-carousel-card-subsection-description-font-color-hover: var(--course-ribbon-carousel-card-section-description-font-color-hover);
  --course-ribbon-carousel-card-subsection-description-font-color-selected: var(--course-ribbon-carousel-card-section-description-font-color-selected);
  --course-ribbon-carousel-card-subsection-description-font-color: var(--course-ribbon-carousel-card-section-description-font-color);
  --course-ribbon-carousel-card-subsection-description-font-size: var(--course-ribbon-carousel-card-section-description-font-size);
  --course-ribbon-carousel-card-subsection-description-font-weight-hover: var(--course-ribbon-carousel-card-section-description-font-weight-hover);
  --course-ribbon-carousel-card-subsection-description-font-weight-selected: var(--course-ribbon-carousel-card-section-description-font-weight-selected);
  --course-ribbon-carousel-card-subsection-description-font-weight: var(--course-ribbon-carousel-card-section-description-font-weight);
  --course-ribbon-carousel-card-subsection-description-height: var(--course-ribbon-carousel-card-section-description-height);
  --course-ribbon-carousel-card-subsection-description-width: var(--course-ribbon-carousel-card-section-description-width);
  --course-ribbon-carousel-card-subsection-title-font-color-hover: var(--course-ribbon-carousel-card-section-title-font-color-hover);
  --course-ribbon-carousel-card-subsection-title-font-color-selected: var(--course-ribbon-carousel-card-section-title-font-color-selected);
  --course-ribbon-carousel-card-subsection-title-font-color: var(--course-ribbon-carousel-card-section-title-font-color);
  --course-ribbon-carousel-card-subsection-title-font-size: var(--course-ribbon-carousel-card-section-title-font-size);
  --course-ribbon-carousel-card-subsection-title-font-weight-hover: var(--course-ribbon-carousel-card-section-title-font-weight-hover);
  --course-ribbon-carousel-card-subsection-title-font-weight-selected: var(--course-ribbon-carousel-card-section-title-font-weight-selected);
  --course-ribbon-carousel-card-subsection-title-font-weight: var(--course-ribbon-carousel-card-section-title-font-weight);
  --course-ribbon-carousel-card-subsection-title-height: var(--course-ribbon-carousel-card-section-title-height);
  --course-ribbon-carousel-card-subsection-title-width: var(--course-ribbon-carousel-card-section-title-width);
  --course-ribbon-carousel-card-subsection-width: var(--course-ribbon-carousel-card-section-width);
  --course-ribbon-carousel-card-text-wrapper-height: 34px;
  --course-ribbon-carousel-card-unit-bg-color-hover: var(--course-ribbon-carousel-card-unit-bg-color);
  --course-ribbon-carousel-card-unit-bg-color-selected: #99ed77;
  --course-ribbon-carousel-card-unit-bg-color: #efefef;
  --course-ribbon-carousel-card-unit-border-hover: var(--course-ribbon-carousel-card-unit-border);
  --course-ribbon-carousel-card-unit-border-selected: 1px solid #707070;
  --course-ribbon-carousel-card-unit-border: 1px solid #707070;
  --course-ribbon-carousel-card-unit-description-font-color-hover: rgba(0, 0, 0, 0.87);
  --course-ribbon-carousel-card-unit-description-font-color-selected: #000;
  --course-ribbon-carousel-card-unit-description-font-color: #000;
  --course-ribbon-carousel-card-unit-description-font-size: 12px;
  --course-ribbon-carousel-card-unit-description-font-weight-hover: normal;
  --course-ribbon-carousel-card-unit-description-font-weight-selected: normal;
  --course-ribbon-carousel-card-unit-description-font-weight: normal;
  --course-ribbon-carousel-card-unit-description-height: 18px;
  --course-ribbon-carousel-card-unit-description-width: 123px;
  --course-ribbon-carousel-card-unit-image-bg-color: #fff;
  --course-ribbon-carousel-card-unit-image-border-radius: 0;
  --course-ribbon-carousel-card-unit-image-border: 1px solid #707070;
  --course-ribbon-carousel-card-unit-image-default-bg-color-selected: var(--course-ribbon-carousel-card-unit-bg-color-selected);
  --course-ribbon-carousel-card-unit-image-default-bg-color: #afafaf;
  --course-ribbon-carousel-card-unit-image-default-border-selected: 1px solid transparent;
  --course-ribbon-carousel-card-unit-image-default-border: var(--course-ribbon-carousel-card-unit-image-border);
  --course-ribbon-carousel-card-unit-image-default-padding-inner: 0 0 4px 0;
  --course-ribbon-carousel-card-unit-image-default-padding: 0px 15px;
  --course-ribbon-carousel-card-unit-image-height: 75px;
  --course-ribbon-carousel-card-unit-image-width: 103px;
  --course-ribbon-carousel-card-unit-title-font-color-hover: rgba(0, 0, 0, 0.87);
  --course-ribbon-carousel-card-unit-title-font-color-selected: #000;
  --course-ribbon-carousel-card-unit-title-font-color: #000;
  --course-ribbon-carousel-card-unit-title-font-size: 13px;
  --course-ribbon-carousel-card-unit-title-font-weight-hover: bold;
  --course-ribbon-carousel-card-unit-title-font-weight-selected: bold;
  --course-ribbon-carousel-card-unit-title-font-weight: bold;
  --course-ribbon-carousel-card-unit-title-height: 18px;
  --course-ribbon-carousel-card-unit-title-width: 123px;
  --course-ribbon-carousel-card-unit-width: 188px;
  --course-ribbon-carousel-container-section-bg-color: transparent;
  --course-ribbon-carousel-container-section-border-radius: 0;
  --course-ribbon-carousel-container-section-border: none;
  --course-ribbon-carousel-container-section-height: 54px;
  --course-ribbon-carousel-container-section-width: 970px;
  --course-ribbon-carousel-container-subsection-bg-color: var(--course-ribbon-carousel-container-section-bg-color);
  --course-ribbon-carousel-container-subsection-border-radius: var(--course-ribbon-carousel-container-section-border-radius);
  --course-ribbon-carousel-container-subsection-border: var(--course-ribbon-carousel-container-section-border);
  --course-ribbon-carousel-container-subsection-height: 34px;
  --course-ribbon-carousel-container-subsection-slick-slider-border-radius: var(--course-ribbon-carousel-container-section-slick-slider-border-radius);
  --course-ribbon-carousel-container-subsection-width: var(--course-ribbon-carousel-container-section-width);
  --course-ribbon-carousel-container-unit-bg-color: transparent;
  --course-ribbon-carousel-container-unit-border: none;
  --course-ribbon-carousel-container-unit-height: 114px;
  --course-ribbon-carousel-container-unit-width: 1127px;
  --course-ribbon-carousel-resources-container-margin: 0;
  --course-ribbon-carousel-section-border-radius: 4px;
  --course-ribbon-carousel-section-height: 54px;
  --course-ribbon-carousel-section-margin: 26px auto 33px auto;
  --course-ribbon-carousel-section-padding: 10px 80px 10px 80px;
  --course-ribbon-carousel-slick-slider-background: transparent;
  --course-ribbon-carousel-slick-slider-border: none;
  --course-ribbon-carousel-subsection-border-radius: var(--course-ribbon-carousel-section-border-radius);
  --course-ribbon-carousel-subsection-height: var(--course-ribbon-carousel-section-height);
  --course-ribbon-carousel-subsection-margin: 14px auto 0 auto;
  --course-ribbon-carousel-subsection-padding: var(--course-ribbon-carousel-section-padding);
  --course-ribbon-carousel-toc-loader-margin: 30px auto auto auto;
  --course-ribbon-carousel-unit-resources-container-margin: 20px auto 0 auto;
  --course-search-list-container-width: 900px;
  --course-search-list-header-min-width: 914px;
  --course-tree-branch-card-image-wrapper-margin: 0;
  --course-tree-card-height: 256px;
  --course-tree-card-width: 293px;
  --course-tree-leaf-card-border-radius: var(--border-radius) 0 0 var(--border-radius);
  --course-tree-leaf-card-image-wrapper-margin: 0;
  --custom-card-color: #1f8484;
  --custom-dropdown-font-size: 13px;
  --custom-dropdown-label-border-radius: 4px 0 0 4px;
  --custom-dropdown-label-list-active-border-radius: 0 4px 0 0;
  --custom-dropdown-label-list-border-radius: 0 4px 4px 0;
  --custom-dropdown-list-border-color: rgba(34, 36, 38, 0.15);
  --custom-dropdown-list-icon-font-size: 17px;
  --custom-dropdown-list-icon-font-weight: bold;
  --custom-dropdown-list-item-padding: 9px 7px;
  --custom-dropdown-list-item-text-max-width: 195px;
  --custom-dropdown-list-width: 237px;
  --dashboard-add-join-class-button-bg-color-hover: var(--theme-primary-banner-button-hover-color);
  --dashboard-add-join-class-button-bg-color: var(--theme-primary-banner-button-bg-color);
  --dashboard-add-join-class-button-border-radius: var(--border-radius);
  --dashboard-add-join-class-button-border: inherit;
  --dashboard-add-join-class-button-color-hover: #000;
  --dashboard-add-join-class-button-color: #000;
  --dashboard-add-join-class-button-font-size: var(--theme-font-size);
  --dashboard-add-join-class-button-font-weight: 600;
  --dashboard-add-join-class-button-height: 33px;
  --dashboard-add-join-class-button-margin: inherit;
  --dashboard-add-join-class-button-max-height: auto;
  --dashboard-add-join-class-button-max-width: auto;
  --dashboard-add-join-class-button-padding: 3px 0px 0px 0px;
  --dashboard-add-join-class-button-width: 130px;
  --dashboard-welcome-date-font-size-student: 26px;
  --dashboard-welcome-date-font-size: 15px;
  --dashboard-welcome-date-font-weight-student: 500;
  --dashboard-welcome-date-font-weight: 500;
  --dashboard-welcome-date-letter-spacing-student: inherit;
  --dashboard-welcome-date-letter-spacing: inherit;
  --dashboard-welcome-header-font-size-student: 38px;
  --dashboard-welcome-header-font-size: 30px;
  --dashboard-welcome-header-font-weight-student: bold;
  --dashboard-welcome-header-font-weight: bold;
  --demolink-launcher-box-button-height: auto;
  --demolink-launcher-box-description-font-size: 16px;
  --demolink-launcher-box-description-font-weight: normal;
  --demolink-launcher-box-description-padding: 0 0 20px 0;
  --demolink-launcher-box-min-height: 275px;
  --demolink-launcher-box-result-min-height: 195px;
  --demolink-launcher-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --demolink-launcher-box-title-font-size: 30px;
  --demolink-launcher-box-title-font-weight: bold;
  --demolink-launcher-box-width: 550px;
  --disabled-text-color: #afafaf;
  --dot-menu-color: var(--theme-primary-button-color);
  --dynamic-login-registration-url: none;
  --engagement-resources-cell-background-positive: #e0ead2;
  --expired-license-caution-popup-content-label-font-size: 14px;
  --expired-license-caution-popup-content-label-line-height: 20px;
  --export-theme-font-size: 14px;
  --fav-icon: none;
  --filter-card-header-label-font-size: 18px;
  --filter-card-label-font-size: var(--theme-font-size);
  --flag-background-color: white;
  --flag-color: #000000;
  --flexible-assignment-modal-action-button-padding: var(--theme-button-default-padding);
  --flexible-assignment-modal-body-height: 101%;
  --font-text-rendering: auto;
  --font-text-smoothing: auto;
  --font-weight-bold-secondary: bold;
  --font-weight-bold: bold;
  --footer-background-color: #172738;
  --footer-link-color: white;
  --fullscreen-modal-header-button-min-height: var(--modal-header-button-min-height);
  --fullscreen-modal-header-button-padding: var(--modal-header-button-padding);
  --generic-launcher-box-actions-gap: 5px;
  --generic-launcher-box-border-radius: 6px 6px 4px 4px;
  --generic-launcher-box-button-height: auto;
  --generic-launcher-box-button-padding: var(--theme-button-default-padding);
  --generic-launcher-box-description-font-size: 16px;
  --generic-launcher-box-description-font-weight: normal;
  --generic-launcher-box-description-padding: 0;
  --generic-launcher-box-input-width: 275px;
  --generic-launcher-box-min-height-large: 265px;
  --generic-launcher-box-min-height: 200px;
  --generic-launcher-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --generic-launcher-box-title-background-color: var(--theme-header-bg-color-login);
  --generic-launcher-box-title-border-radius: 4px 4px 0 0;
  --generic-launcher-box-title-color: var(--theme-header-color-login);
  --generic-launcher-box-title-font-size: var(--theme-header-font-size-login);
  --generic-launcher-box-title-font-weight: bold;
  --generic-launcher-box-title-height: var(--theme-header-height-login);
  --generic-launcher-box-title-padding: 6px 0 0 0;
  --generic-launcher-box-width: 550px;
  --generic-modal-action-button-font-size: var(--theme-font-size);
  --generic-modal-action-button-font-weight: 600;
  --generic-modal-action-button-min-height: 30px;
  --generic-modal-action-button-min-width: fit-content;
  --generic-modal-action-button-width: 90px;
  --generic-modal-body-font-size: 15px;
  --gradebook-active-tab-nav-background-color: var(--assignment-active-tab-nav-background-color);
  --gradebook-active-tab-nav-color: var(--assignment-active-tab-nav-color);
  --gradebook-active-tab-nav-hover-color: var(--assignment-active-tab-nav-hover-color);
  --gradebook-detail-colorkey-font-size: var(--theme-font-size);
  --gradebook-detail-full-credit-font-color-hover: var(--gradebook-detail-full-credit-font-color);
  --gradebook-detail-full-credit-font-color: #202020;
  --gradebook-detail-full-credit-hover: #cddfbb;
  --gradebook-detail-full-credit: #e0efd1;
  --gradebook-detail-no-credit-font-color-hover: var(--gradebook-detail-no-credit-font-color);
  --gradebook-detail-no-credit-font-color: #202020;
  --gradebook-detail-no-credit-hover: #e4b4b5;
  --gradebook-detail-no-credit: #f2c6c7;
  --gradebook-detail-partial-credit-font-color-hover: var(--gradebook-detail-partial-credit-font-color);
  --gradebook-detail-partial-credit-font-color: #202020;
  --gradebook-detail-partial-credit-hover: #e5cb97;
  --gradebook-detail-partial-credit: #fadfaa;
  --gradebook-details-table-cell-activity-number-max-width: 100px;
  --gradebook-details-table-cell-activity-number-width: 75px;
  --gradebook-details-table-cell-end-date-max-width: 106px;
  --gradebook-details-table-cell-first-name-width: 145px;
  --gradebook-details-table-cell-grade-width: 80px;
  --gradebook-details-table-cell-last-name-width: 145px;
  --gradebook-details-table-cell-likert-average-width: 80px;
  --gradebook-details-table-cell-score-width: 70px;
  --gradebook-details-table-cell-start-date-width: 106px;
  --gradebook-details-table-cell-typing-height: 45px;
  --gradebook-details-table-cell-typing-keyboarding-padding-hover: 4px 0 0 0;
  --gradebook-details-table-cell-typing-keyboarding-padding: 2px 0 0 0;
  --gradebook-details-table-cell-usage-width: 80px;
  --gradebook-details-topleft-gap: 20px;
  --gradebook-export-button-bg-color-hover: var(--gradebook-export-button-bg-color);
  --gradebook-export-button-bg-color: var(--theme-primary-button-color);
  --gradebook-export-button-border-color: var(--gradebook-export-button-color);
  --gradebook-export-button-color: var(--theme-basic-button-color);
  --gradebook-header-classroom-name-color: var(--theme-header-title-color);
  --gradebook-header-classroom-name-margin: 0 0 16px 0;
  --gradebook-header-classroom-name-opacity: var(--gradebook-header-subtitle-opacity);
  --gradebook-header-right-button-bg-color-hover: var(--theme-primary-banner-button-hover-color);
  --gradebook-header-right-button-bg-color: var(--theme-primary-banner-button-bg-color);
  --gradebook-header-right-button-border: inherit;
  --gradebook-header-right-button-bottom-gap: inherit;
  --gradebook-header-right-button-color-hover: var(--theme-primary-banner-button-color);
  --gradebook-header-right-button-color: var(--theme-primary-banner-button-color);
  --gradebook-header-right-button-font-size: var(--theme-primary-banner-button-font-size);
  --gradebook-header-right-button-font-weight: var(--theme-primary-banner-button-font-weight);
  --gradebook-header-right-button-height: 26px;
  --gradebook-header-right-button-padding: inherit;
  --gradebook-header-right-button-top-gap: inherit;
  --gradebook-header-right-vertical-gap: 16px;
  --gradebook-header-subtitle-color: var(--theme-header-title-color);
  --gradebook-header-subtitle-opacity: 1;
  --gradebook-likert-full-credit-font-color-hover: var(--gradebook-detail-full-credit-font-color-hover);
  --gradebook-likert-full-credit-font-color: var(--gradebook-detail-full-credit-font-color);
  --gradebook-likert-full-credit-hover: var(--gradebook-detail-full-credit-hover);
  --gradebook-likert-full-credit: var(--gradebook-detail-full-credit);
  --gradebook-likert-no-credit-font-color-hover: var(--gradebook-detail-no-credit-font-color-hover);
  --gradebook-likert-no-credit-font-color: var(--gradebook-detail-no-credit-font-color);
  --gradebook-likert-no-credit-hover: var(--gradebook-detail-no-credit-hover);
  --gradebook-likert-no-credit: var(--gradebook-detail-no-credit);
  --gradebook-likert-partial-credit-font-color-hover: var(--gradebook-detail-partial-credit-font-color-hover);
  --gradebook-likert-partial-credit-font-color: var(--gradebook-detail-partial-credit-font-color);
  --gradebook-likert-partial-credit-hover: var(--gradebook-detail-partial-credit-hover);
  --gradebook-likert-partial-credit: var(--gradebook-detail-partial-credit);
  --gradebook-tab-nav-background-color: var(--assignment-tab-nav-background-color);
  --gradebook-tab-nav-border-right: var(--assignment-tab-nav-border-right);
  --gradebook-tab-nav-border: inherit;
  --gradebook-tab-nav-color: var(--assignment-tab-nav-color);
  --gradebook-tab-nav-font-size: var(--assignment-tab-nav-font-size);
  --gradebook-tab-nav-font-weight: var(--assignment-tab-nav-font-weight);
  --gradebook-tab-nav-hover-color: var(--assignment-tab-nav-hover-color);
  --gradebook-tab-nav-padding-bottom: var(--assignment-tab-nav-padding-bottom);
  --gradebook-table-cell-activity-number-width: 100px;
  --gradebook-table-cell-assigned-type-width: 60px;
  --gradebook-table-cell-assignment-status-width: 100px;
  --gradebook-table-cell-assignment-student-status-width: 100px;
  --gradebook-table-cell-end-date-width: 90px;
  --gradebook-table-cell-feedback-width: 87px;
  --gradebook-table-cell-first-name-width: 130px;
  --gradebook-table-cell-grade-width: 75px;
  --gradebook-table-cell-grades-reports-width: 95px;
  --gradebook-table-cell-grades-review-width: 85px;
  --gradebook-table-cell-grades-students-width: 95px;
  --gradebook-table-cell-last-name-width: 130px;
  --gradebook-table-cell-start-date-width: 90px;
  --gradebook-table-cell-student-version-width: 80px;
  --gradebook-table-cell-submitted-max-width: fit-content;
  --gradebook-table-cell-submitted-width: 97px;
  --header-title-font-size: 28px;
  --header-title-font-weight: 900;
  --impersonation-caution-banner-min-height: 52px;
  --info-icon-caution-height: 27px;
  --info-icon-caution-width: auto;
  --info-icon-line-height: 1.2;
  --input-border-radius: var(--border-radius);
  --item-image-width: 150px;
  --library-resource-card-banner-classrooms-padding: 0 12px;
  --library-sidenav-button-bg-color-hover: var(--library-sidenav-button-bg-color);
  --library-sidenav-button-bg-color-selected-hover: var(--theme-primary-button-hover-color);
  --library-sidenav-button-bg-color-selected: var(--theme-primary-button-bg-color);
  --library-sidenav-button-bg-color: transparent none;
  --library-sidenav-button-border-hover: var(--library-sidenav-button-border);
  --library-sidenav-button-border-selected-hover: var(--library-sidenav-button-border-selected);
  --library-sidenav-button-border-selected: solid var(--theme-button-default-border-size) var(--theme-basic-button-color);
  --library-sidenav-button-border: solid var(--theme-button-default-border-size) var(--theme-basic-button-color);
  --library-sidenav-button-font-color-selected: var(--theme-primary-button-color);
  --library-sidenav-button-font-color: var(--theme-basic-button-color);
  --library-sidenav-button-font-size: var(--theme-secondary-font-size);
  --library-sidenav-button-font-weight-selected: bold;
  --library-sidenav-button-font-weight: bold;
  --login-body-height: 100vh;
  --login-button-bg-color-hover: var(--theme-primary-button-hover-color);
  --login-button-bg-color: var(--theme-primary-button-bg-color);
  --login-button-border-radius: var(--theme-secondary-button-border-radius);
  --login-button-border: inherit;
  --login-button-font-color-hover: var(--theme-primary-button-color);
  --login-button-font-color: var(--theme-primary-button-color);
  --login-button-font-size: 16px;
  --login-button-font-weight: bold;
  --login-button-height: 41px;
  --login-button-line-height: normal;
  --login-button-padding: inherit;
  --login-button-width: 122px;
  --login-center-logo-background: inherit;
  --login-center-logo-display: inherit;
  --login-center-logo-height: auto;
  --login-center-logo-margin: auto;
  --login-center-logo-width: auto;
  --login-external-purchase-url: none;
  --login-header-logo-background: var(--nav-logo-background);
  --login-header-logo-cursor: default;
  --login-header-logo-display: inherit;
  --login-header-logo-height: auto;
  --login-header-logo-width: auto;
  --login-help-font-size: 16px;
  --login-help-font-weight: 500;
  --login-help-link-color: var(--theme-link-color);
  --login-input-font-size: 13px;
  --login-label-font-color: var(--theme-font-color);
  --login-label-font-size: 13px;
  --login-label-font-weight: normal;
  --login-label-forgot-link-margin: 10px 0 0 0;
  --login-label-link-color: var(--theme-link-color);
  --login-password-button-bg-color-hover: var(--login-password-button-bg-color);
  --login-password-button-bg-color: transparent;
  --login-password-button-border-radius: var(--theme-button-border-radius);
  --login-password-button-border: 2px solid var(--theme-basic-button-border-color);
  --login-password-button-font-size: var(--theme-font-size);
  --login-password-button-font-weight: normal;
  --login-password-button-foreground-color-hover: var(--theme-basic-button-hover-color);
  --login-password-button-foreground-color: var(--theme-basic-button-color);
  --login-password-button-height: 27.5px;
  --login-password-button-margin-left: -106px;
  --login-password-button-padding: 8px 0;
  --login-password-button-width: 100px;
  --login-register-body-height: 100vh;
  --login-register-button-bg-color-hover: var(--login-register-button-bg-color);
  --login-register-button-bg-color: transparent;
  --login-register-button-border-radius: var(--theme-secondary-button-border-radius);
  --login-register-button-border: 1px solid var(--theme-basic-button-border-color);
  --login-register-button-font-size: 16px;
  --login-register-button-font-weight: bold;
  --login-register-button-foreground-color-hover: var(--theme-basic-button-hover-color);
  --login-register-button-foreground-color: var(--theme-basic-button-color);
  --login-register-button-height: 41px;
  --login-register-button-line-height: normal;
  --login-register-button-padding: inherit;
  --login-register-button-width: 114px;
  --login-register-ecommerce-body-height: 100%;
  --login-sso-button-font-color: #fff;
  --login-sso-button-font-size: 16px;
  --login-sso-button-font-weight: 900;
  --lti-menu-item-padding: 25px 26px;
  --modal-action-button-min-height: var(--theme-primary-button-min-height);
  --modal-action-button-padding: var(--theme-button-default-padding);
  --modal-banner-title-container-info-popup-margin: 0;
  --modal-footer-color: #f9f9f9;
  --modal-footer-height: 60px;
  --modal-footer-separator-color: #d4d4d5;
  --modal-header-bar-align-items: center;
  --modal-header-bar-banner-button-close-padding: 5px 6px 11px;
  --modal-header-bar-display: flex;
  --modal-header-button-min-height: var(--theme-primary-button-min-height);
  --modal-header-button-padding: var(--theme-button-default-padding);
  --modal-header-color: #f9f9f9;
  --modal-header-height: 60px;
  --modal-header-separator-color: #d4d4d5;
  --modal-title-text-color: rgba(255, 255, 255, 0.87);
  --modal-title-text-font-weight: 700;
  --nav-height: 50px;
  --nav-logo-background: inherit;
  --nav-logo-height: auto;
  --nav-logo-margin: 0;
  --nav-logo-width: auto;
  --nav-separator-background: var(--theme-secondary-border-color);
  --nav-separator-box-shadow: none;
  --nav-separator-dashboard-height: var(--nav-separator-height);
  --nav-separator-height: 0px;
  --not-found-view-actions-padding: 14px 0 0 0;
  --not-found-view-body-font-size: 16px;
  --not-found-view-container-color: var(--theme-font-color);
  --not-found-view-container-segment-padding: 30px 0 20px 0;
  --notifications-button-bar-container-padding: 0 0 30px 0;
  --notifications-view-header-padding: 50px 0 30px 0;
  --observational-action-button-default-font-size: 13px;
  --observational-action-button-default-font-weight: bold;
  --observational-action-button-default-min-height: 33px;
  --observational-action-button-default-padding-left: 16px;
  --observational-action-button-default-padding-right: 16px;
  --observational-action-button-full-credit-font-size: var(--observational-action-button-default-font-size);
  --observational-action-button-full-credit-font-weight: var(--observational-action-button-default-font-weight);
  --observational-action-button-full-credit-min-height: var(--observational-action-button-default-min-height);
  --observational-action-button-full-credit-padding-left: var(--observational-action-button-default-padding-left);
  --observational-action-button-full-credit-padding-right: var(--observational-action-button-default-padding-right);
  --observational-action-button-save-score-font-size: var(--observational-action-button-default-font-size);
  --observational-action-button-save-score-font-weight: var(--observational-action-button-default-font-weight);
  --observational-action-button-save-score-min-height: var(--observational-action-button-default-min-height);
  --observational-action-button-save-score-padding-left: var(--observational-action-button-default-padding-left);
  --observational-action-button-save-score-padding-right: var(--observational-action-button-default-padding-right);
  --observational-action-buttons-wrapper-gap: 10px;
  --observational-comment-ckeditor-height: 80px;
  --observational-comment-ckeditor-width: 238px;
  --observational-comment-input-wrapper-padding: 10px 0;
  --observational-max-score-label-padding: 0 0 0 4px;
  --observational-rollover-ck-icon-height: 16px;
  --observational-rollover-ck-icon-width: 16px;
  --observational-rollover-ck-toolbar-z-index: 9200;
  --observational-rollover-close-button-border: none;
  --observational-rollover-close-button-padding: 0;
  --observational-score-input-height: 35px;
  --observational-score-input-label-padding: 0 8px 0 0;
  --observational-score-input-padding: 11px 3px 11px 3px;
  --observational-score-input-width: 40px;
  --offline-msg-box-body-bg-color: #f2f2f2;
  --offline-msg-box-body-font-size: 20px;
  --offline-msg-box-body-font-weight: bold;
  --offline-msg-box-body-img-wrapper-height: 325px;
  --offline-msg-box-body-text-color: #484848;
  --offline-msg-box-body-text-line-height: 1.5;
  --offline-msg-box-body-text-wrapper-height: 120px;
  --offline-msg-box-header-bg-color: #767676;
  --offline-msg-box-header-font-size: 22px;
  --offline-msg-box-header-font-weight: bold;
  --offline-msg-box-header-height: 50px;
  --offline-msg-box-header-text-color: #fff;
  --outline-color: #3a9cfa;
  --product-course-card-nav-button-font-weight: 600;
  --profile-menu-item-font-size: 1em;
  --profile-save-changes-button-background-color: var(--theme-primary-button-bg-color);
  --radio-label-checked-font-weight: bold;
  --radio-wrapper-font-weight: var(--header-title-font-weight);
  --report-breadcrumbs-margin: 5px 0 0 0;
  --report-button-pie-image-filter: none;
  --report-caption-title-font-size: 22px;
  --report-caption-title-font-weight: bold;
  --report-context-switcher-active-button-background-color: var(--assignment-active-tab-nav-background-color);
  --report-context-switcher-active-button-border: var(--report-context-switcher-button-border);
  --report-context-switcher-active-button-color: var(--assignment-active-tab-nav-color);
  --report-context-switcher-active-button-hover-color: var(--assignment-active-tab-nav-hover-color);
  --report-context-switcher-button-background-color: var(--assignment-tab-nav-background-color);
  --report-context-switcher-button-border-radius: var(--theme-button-radius);
  --report-context-switcher-button-border: inherit;
  --report-context-switcher-button-color: var(--assignment-tab-nav-color);
  --report-context-switcher-button-font-size: var(--assignment-tab-nav-font-size);
  --report-context-switcher-button-font-weight: var(--assignment-tab-nav-font-weight);
  --report-context-switcher-button-gap: 0;
  --report-context-switcher-button-height: 33px;
  --report-context-switcher-button-hover-color: var(--assignment-tab-nav-hover-color);
  --report-context-switcher-button-padding: var(--theme-button-default-padding);
  --report-default-approaching: #e5a812;
  --report-default-developing: #c72339;
  --report-default-foreground-color: white;
  --report-default-mastering: #003abe;
  --report-default-meeting: #21ba45;
  --report-default-not-enough-data: #767676;
  --report-download-button-bg-color-hover: transparent;
  --report-download-button-bg-color: transparent;
  --report-download-button-border-color: var(--theme-primary-button-border-color2);
  --report-download-button-color: var(--theme-primary-button-bg-color);
  --report-download-button-default-margin-left: 13px;
  --report-download-button-font-size: var(--theme-font-size);
  --report-download-button-font-weight: 600;
  --report-download-button-icon-font-size: var(--theme-font-size);
  --report-download-button-min-height: 26px;
  --report-download-button-min-width: 98px;
  --report-download-button-padding: 0;
  --report-engagement-high: #c1d699;
  --report-engagement-low: #e59b99;
  --report-expandable-approaching: var(--report-default-approaching);
  --report-expandable-developing: var(--report-default-developing);
  --report-expandable-foreground-color: var(--report-default-foreground-color);
  --report-expandable-mastering: var(--report-default-mastering);
  --report-expandable-meeting: var(--report-default-meeting);
  --report-expandable-not-enough-data: var(--report-default-not-enough-data);
  --report-expandable-standards-approaching: var(--report-standards-approaching);
  --report-expandable-standards-developing: var(--report-standards-developing);
  --report-expandable-standards-foreground-color: var(--report-standards-foreground-color);
  --report-expandable-standards-mastering: var(--report-standards-mastering);
  --report-expandable-standards-meeting: var(--report-standards-meeting);
  --report-expandable-standards-not-enough-data: var(--report-standards-not-enough-data);
  --report-header-color-key-card-visibility: visible;
  --report-header-course-height-individual: 194px;
  --report-header-export-button-padding: inherit;
  --report-header-standards-height-individual: 194px;
  --report-hide-columns-button-bg-color-hover: var(--report-download-button-bg-color-hover);
  --report-hide-columns-button-bg-color: var(--report-download-button-bg-color);
  --report-hide-columns-button-border-color: var(--report-download-button-border-color);
  --report-hide-columns-button-color: var(--report-download-button-color);
  --report-hide-columns-button-font-size: var(--report-download-button-font-size);
  --report-hide-columns-button-font-weight: var(--report-download-button-font-weight);
  --report-hide-columns-button-min-height: var(--report-download-button-min-height);
  --report-hide-columns-button-min-width: 110px;
  --report-hide-columns-button-padding: 1px 0 0 0;
  --report-hide-columns-modal-body-header-label-color: #000;
  --report-hide-columns-modal-body-header-label-font-size: 14px;
  --report-hide-columns-modal-body-header-label-font-weight: bold;
  --report-hide-columns-modal-checkbox-bg-color: var(--checkbox-bg-color);
  --report-hide-columns-modal-faculty-option-label-font-size: 14px;
  --report-hide-columns-modal-faculty-option-label-popup-font-size: 14px;
  --report-hide-columns-reset-all-color: var(--theme-link-color);
  --report-hide-columns-reset-all-font-size: 12px;
  --report-hide-columns-reset-all-font-weight: bold;
  --report-likert-default-approaching: var(--report-default-approaching);
  --report-likert-default-developing: var(--report-default-developing);
  --report-likert-default-foreground-color: var(--report-default-foreground-color);
  --report-likert-default-mastering: var(--report-default-mastering);
  --report-likert-default-meeting: var(--report-default-meeting);
  --report-likert-default-not-enough-data: var(--report-default-not-enough-data);
  --report-likert-expandable-approaching: var(--report-likert-default-approaching);
  --report-likert-expandable-developing: var(--report-likert-default-developing);
  --report-likert-expandable-foreground-color: var(--report-likert-default-foreground-color);
  --report-likert-expandable-mastering: var(--report-likert-default-mastering);
  --report-likert-expandable-meeting: var(--report-likert-default-meeting);
  --report-likert-expandable-not-enough-data: var(--report-likert-default-not-enough-data);
  --report-likert-expandable-standards-approaching: var(--report-likert-standards-approaching);
  --report-likert-expandable-standards-developing: var(--report-likert-standards-developing);
  --report-likert-expandable-standards-foreground-color: var(--report-likert-standards-foreground-color);
  --report-likert-expandable-standards-mastering: var(--report-likert-standards-mastering);
  --report-likert-expandable-standards-meeting: var(--report-likert-standards-meeting);
  --report-likert-expandable-standards-not-enough-data: var(--report-likert-standards-not-enough-data);
  --report-likert-header-color-key-card-visibility: var(--report-header-color-key-card-visibility);
  --report-likert-standards-approaching: var(--report-likert-default-approaching);
  --report-likert-standards-developing: var(--report-likert-default-developing);
  --report-likert-standards-foreground-color: var(--report-likert-default-foreground-color);
  --report-likert-standards-mastering: var(--report-likert-default-mastering);
  --report-likert-standards-meeting: var(--report-likert-default-meeting);
  --report-likert-standards-not-enough-data: var(--report-likert-default-not-enough-data);
  --report-progress-average-less: #b2cc80;
  --report-progress-average-more: #e0eacc;
  --report-progress-high-less: #809dc8;
  --report-progress-high-more: #ccd8e9;
  --report-progress-low-less: #d7858d;
  --report-progress-low-more: #efced1;
  --report-progress-numbers-toggler-margin-left-offset: '0px';
  --report-standards-approaching: var(--report-default-approaching);
  --report-standards-developing: var(--report-default-developing);
  --report-standards-foreground-color: var(--report-default-foreground-color);
  --report-standards-mastering: var(--report-default-mastering);
  --report-standards-meeting: var(--report-default-meeting);
  --report-standards-not-enough-data: var(--report-default-not-enough-data);
  --report-table-border-top: none;
  --report-table-border: 0;
  --report-theme-font-size: var(--theme-font-size);
  --report-theme-header-font-size: 14px;
  --report-type-selector-view-classroom-button-height: fit-content;
  --report-type-selector-view-classroom-button-padding-top: 3px;
  --report-type-selector-view-district-button-height: fit-content;
  --report-type-selector-view-district-button-padding-top: 3px;
  --report-type-selector-view-school-button-height: fit-content;
  --report-type-selector-view-school-button-padding-top: 3px;
  --report-usage-less-time-high-performance: #809dc8;
  --report-usage-less-time-low-performance: #d7858d;
  --report-usage-more-time-high-performance: #ccd8e9;
  --report-usage-more-time-low-performance: #efced1;
  --resource-bank-filter-instruction-width: auto;
  --resource-pacing-modal-font-size: var(--theme-font-size);
  --resource-pacing-modal-font-weight: normal;
  --rollover-loading-default-padding: 10px;
  --roster-tab-background-color: rgba(0, 0, 0, 0);
  --roster-tab-border-color: rgba(0, 0, 0, 0.2);
  --roster-tab-font-color: var(--theme-font-color-directly-on-background);
  --roster-tab-selected-background-color: var(--theme-dark-border-color);
  --roster-tab-selected-border-color: var(--theme-dark-border-color);
  --roster-tab-selected-font-color: #fff;
  --roster-table-cell-button-default-font-size: 11px;
  --roster-table-cell-default-width: 19%;
  --roster-table-cell-medium-width: 10%;
  --roster-table-cell-small-width: 10%;
  --roster-table-row-padding: 0 16px;
  --scrolling-content-modal-left-max-height: 93.5vh;
  --scrolling-content-modal-right-max-height: 93.5vh;
  --secondary-modal-header-height: 65px;
  --settings-save-button-height: 26px;
  --settings-save-button-width: 90px;
  --stacked-bar-display: inline-grid;
  --standards-list-name-pill-margin: 2.5px;
  --standards-list-name-pill-padding: 0 5px 0 5px;
  --student-assignment-card-button-bg-color-hover: inherit;
  --student-assignment-card-button-bg-color: inherit;
  --student-assignment-card-button-border-radius: inherit;
  --student-assignment-card-button-border: none;
  --student-assignment-card-button-color-hover: inherit;
  --student-assignment-card-button-color: inherit;
  --student-assignment-card-button-font-size: inherit;
  --student-assignment-card-button-font-weight: inherit;
  --student-assignment-card-button-gap: 3px;
  --student-assignment-card-button-height: inherit;
  --student-assignment-card-button-margin: inherit;
  --student-assignment-card-button-padding: inherit;
  --student-assignment-card-button-width: inherit;
  --student-assignment-card-content-padding-top: 6px;
  --student-assignment-card-course-info-height: auto;
  --student-assignment-card-footer-bottom-label-font-size: var(--student-assignment-card-footer-label-font-size);
  --student-assignment-card-footer-bottom-padding-top: 15px;
  --student-assignment-card-footer-bottom-reduce-padding-top: 6px;
  --student-assignment-card-footer-bottom-value-font-size: var(--student-assignment-card-footer-value-font-size);
  --student-assignment-card-footer-bottom-value-font-weight: 500;
  --student-assignment-card-footer-bottom-value-submitted-status-font-weight: var(--student-assignment-card-footer-bottom-value-font-weight);
  --student-assignment-card-footer-label-font-size: var(--theme-font-size);
  --student-assignment-card-footer-value-font-size: var(--theme-font-size);
  --student-assignment-card-img-border-radius: 4px;
  --student-assignment-card-max-width: 1023px;
  --student-assignment-card-min-height: 115px;
  --student-assignment-card-min-width: 1023px;
  --student-assignment-card-padding: 0;
  --student-assignment-card-spacer-color: #000;
  --student-assignment-card-spacer-font-weight: normal;
  --student-assignment-card-spacer-margin: 0 10px 0 10px;
  --student-assignment-card-subtitle-font-size: var(--theme-font-size);
  --student-assignment-card-title-align-items: center;
  --student-assignment-card-title-wrapper-height: auto;
  --student-assignment-card-try-again-button-padding: inherit;
  --student-assignment-header-background-color: inherit;
  --student-assignment-header-background-image: inherit;
  --student-assignment-header-background-mix-blend-mode: normal;
  --student-assignment-header-row-height: 78px;
  --student-assignment-header-row-margin-top: 30px;
  --student-assignment-header-row-padding: 0 39px 0 59px;
  --student-assignment-menu-bg-color: var(--theme-banner-color);
  --student-assignment-menu-border-radius: 30px;
  --student-assignment-menu-border: 1px solid #cfcfcf;
  --student-assignment-menu-box-shadow: none;
  --student-assignment-menu-display: flex;
  --student-assignment-menu-item-active-bg-color: var(--theme-secondary-nav-button-bg-color);
  --student-assignment-menu-item-active-color: var(--theme-secondary-nav-button-color);
  --student-assignment-menu-item-active-font-size: 15px;
  --student-assignment-menu-item-active-font-weight: 600;
  --student-assignment-menu-item-bg-color: var(--theme-background-header-color);
  --student-assignment-menu-item-border-right: 1px solid #cfcfcf;
  --student-assignment-menu-item-color: var(--theme-secondary-nav-button-color);
  --student-assignment-menu-item-font-size: 15px;
  --student-assignment-menu-item-font-weight: 600;
  --student-assignment-menu-item-justify-content: center;
  --student-assignment-menu-item-line-height: 1;
  --student-assignment-menu-item-margin: 0;
  --student-assignment-menu-item-min-width: 150px;
  --student-assignment-menu-min-height: fit-content;
  --student-assignment-search-n-sort-width: 42%;
  --student-assignment-today-card-background: inherit;
  --student-assignment-today-card-font-color: inherit;
  --student-assignment-view-container-inner-margin: 0;
  --student-assignment-zero-state-background: inherit;
  --student-assignments-sort-button-border: inherit;
  --student-assignments-sort-button-icon-height: 36px;
  --student-assignments-sort-button-icon-width: 34px;
  --student-border-radius: 20px;
  --student-breadcrumb-chevron-font-size: var(--breadcrumb-chevron-font-size);
  --student-breadcrumb-chevron-font-weight: 500;
  --student-breadcrumb-chevron-foreground-color: var( --student-breadcrumb-foreground-color);
  --student-breadcrumb-chevron-opacity: var(--breadcrumb-chevron-opacity);
  --student-breadcrumb-foreground-color-clickable-hover: var(--theme-link-color-directly-on-background-hover-student);
  --student-breadcrumb-foreground-color-clickable: var(--theme-link-color-directly-on-background-student);
  --student-breadcrumb-foreground-color: var(--theme-font-color-directly-on-background-student);
  --student-breadcrumb-section-font-size-clickable: var(--breadcrumb-section-font-size-clickable);
  --student-breadcrumb-section-font-size: var(--breadcrumb-section-font-size);
  --student-breadcrumb-section-font-weight-clickable: var(--breadcrumb-section-font-weight-clickable);
  --student-breadcrumb-section-font-weight: var(--breadcrumb-section-font-weight);
  --student-breadcrumb-section-margin: 6px 0 0 0;
  --student-breadcrumb-section-text-decoration-clickable-hover: var(--breadcrumb-section-text-decoration-clickable-hover);
  --student-breadcrumb-section-text-decoration-clickable: var(--breadcrumb-section-text-decoration-clickable);
  --student-breadcrumb-wrapper-color: var(--breadcrumb-wrapper-color);
  --student-breadcrumb-wrapper-padding: var(--breadcrumb-wrapper-padding);
  --student-carousel-slick-next-bg-color: var(--theme-student-carousel);
  --student-carousel-slick-next-border-radius: 0px 3px 3px 0px;
  --student-carousel-slick-next-font-color: #707070;
  --student-carousel-slick-next-font-size: 20px;
  --student-carousel-slick-next-font-weight: bold;
  --student-carousel-slick-next-padding: 0 0 0 2px;
  --student-carousel-slick-prev-bg-color: var(--theme-student-carousel);
  --student-carousel-slick-prev-border-radius: 3px 0 0 3px;
  --student-carousel-slick-prev-font-color: #707070;
  --student-carousel-slick-prev-font-size: 20px;
  --student-carousel-slick-prev-font-weight: bold;
  --student-carousel-slick-prev-padding: 0 2px 0 0;
  --student-course-card-basic-button-border-color: var(--course-card-basic-button-border-color);
  --student-course-card-basic-button-border-hover-color: var(--course-card-basic-button-border-hover-color);
  --student-course-card-basic-button-color: var(--course-card-basic-button-color);
  --student-course-card-basic-button-font-size: var(--course-card-basic-button-font-size);
  --student-course-card-basic-button-font-weight: var(--course-card-basic-button-font-weight);
  --student-course-card-basic-button-hover-color: var(--course-card-basic-button-hover-color);
  --student-course-card-basic-button-padding: var(--course-card-basic-button-padding);
  --student-course-card-border-radius: var(--student-border-radius);
  --student-course-card-button-font-size-small: 12px;
  --student-course-card-button-font-size: var(--course-card-button-font-size);
  --student-course-card-button-font-weight: var(--course-card-button-font-weight);
  --student-course-card-button-max-height: var(--course-card-button-max-height);
  --student-course-card-button-min-height: var(--course-card-button-min-height);
  --student-course-card-button-min-width: var(--course-card-button-min-width);
  --student-course-card-leaf-footer-label-font-size: var(--theme-font-size);
  --student-course-card-leaf-footer-value-font-size: var(--theme-font-size);
  --student-course-card-primary-button-bg-color: var(--course-card-primary-button-bg-color);
  --student-course-card-primary-button-color: var(--course-card-primary-button-color);
  --student-course-card-primary-button-font-size: var(--course-card-primary-button-font-size);
  --student-course-card-primary-button-font-weight: var(--course-card-primary-button-font-weight);
  --student-course-card-primary-button-hover-color: var(--course-card-primary-button-hover-color);
  --student-course-card-primary-button-padding: var(--course-card-primary-button-padding);
  --student-course-quick-nav-button-border-radius: var(--course-quick-nav-button-border-radius);
  --student-course-quick-nav-button-font-size: var(--course-quick-nav-button-font-size);
  --student-course-quick-nav-button-font-weight: var(--course-quick-nav-button-font-weight);
  --student-course-quick-nav-button-line-height: var(--course-quick-nav-button-line-height);
  --student-course-quick-nav-button-margin: var(--course-quick-nav-button-margin);
  --student-course-quick-nav-close-button-bg-color-hover: var(--course-quick-nav-close-button-bg-color-hover);
  --student-course-quick-nav-close-button-bg-color: var(--course-quick-nav-close-button-bg-color);
  --student-course-quick-nav-close-button-color-hover: var(--course-quick-nav-close-button-color-hover);
  --student-course-quick-nav-close-button-color: var(--course-quick-nav-close-button-color);
  --student-course-quick-nav-open-button-bg-color-hover: var(--course-quick-nav-open-button-bg-color-hover);
  --student-course-quick-nav-open-button-bg-color: var(--course-quick-nav-open-button-bg-color);
  --student-course-quick-nav-open-button-color-hover: var(--course-quick-nav-open-button-color-hover);
  --student-course-quick-nav-open-button-color: var(--course-quick-nav-open-button-color);
  --student-course-tree-branch-card-image-wrapper-margin: 0;
  --student-course-tree-leaf-card-border-radius: var(--student-border-radius) 0 0 var(--student-border-radius);
  --student-course-tree-leaf-card-image-wrapper-margin: 0;
  --student-dashboard-assignment-card-background: inherit;
  --student-dashboard-assignment-card-min-height: 114px;
  --student-dashboard-assignment-card-padding-left: 0;
  --student-dashboard-assignment-card-padding: 0;
  --student-dashboard-right-column-min-width: 724px;
  --student-dashboard-widget-assignments-border-bottom-left-radius: var(--student-dashboard-widget-default-border-bottom-left-radius);
  --student-dashboard-widget-assignments-border-bottom-right-radius: var(--student-dashboard-widget-default-border-bottom-right-radius);
  --student-dashboard-widget-assignments-border-top-color: #136425;
  --student-dashboard-widget-assignments-button-bg-color-hover: #177e2e;
  --student-dashboard-widget-assignments-button-bg-color: #136425;
  --student-dashboard-widget-assignments-button-border-radius: var(--student-border-radius);
  --student-dashboard-widget-assignments-button-color-hover: #fff;
  --student-dashboard-widget-assignments-button-color: #fff;
  --student-dashboard-widget-assignments-button-font-size: 11px;
  --student-dashboard-widget-assignments-button-font-weight: bold;
  --student-dashboard-widget-assignments-button-height: 26px;
  --student-dashboard-widget-assignments-button-margin: inherit;
  --student-dashboard-widget-assignments-button-padding: 6.5px 10.5px 4.5px;
  --student-dashboard-widget-assignments-button-width: 67px;
  --student-dashboard-widget-assignments-card-line-height: 1.1;
  --student-dashboard-widget-assignments-card-margin-top: 6px;
  --student-dashboard-widget-assignments-content-bg-color: #fff;
  --student-dashboard-widget-assignments-content-height: 540px;
  --student-dashboard-widget-assignments-content-min-height: inherit;
  --student-dashboard-widget-assignments-subtitle-font-size: var(--student-assignment-card-subtitle-font-size);
  --student-dashboard-widget-assignments-subtitle-value-padding-right: 24px;
  --student-dashboard-widget-assignments-title-bg-color: #fff;
  --student-dashboard-widget-assignments-title-color: var(--student-dashboard-widget-default-color);
  --student-dashboard-widget-carousel-border-top-color: #ffcc01;
  --student-dashboard-widget-carousel-button-bg-color-hover: #ffd21f;
  --student-dashboard-widget-carousel-button-bg-color: #ffcc01;
  --student-dashboard-widget-carousel-button-border-radius: var(--student-border-radius);
  --student-dashboard-widget-carousel-button-color-hover: #000;
  --student-dashboard-widget-carousel-button-color: #000;
  --student-dashboard-widget-carousel-button-font-size: 11px;
  --student-dashboard-widget-carousel-button-font-weight: bold;
  --student-dashboard-widget-carousel-button-height: 26px;
  --student-dashboard-widget-carousel-button-margin: 10px auto 14px;
  --student-dashboard-widget-carousel-button-padding: 6.5px 10.5px 4.5px;
  --student-dashboard-widget-carousel-button-width: 105px;
  --student-dashboard-widget-carousel-content-bg-color: #fff;
  --student-dashboard-widget-carousel-display: block;
  --student-dashboard-widget-carousel-title-bg-color: #fff;
  --student-dashboard-widget-carousel-title-color: var(--student-dashboard-widget-default-color);
  --student-dashboard-widget-default-border-bottom-left-radius: 5px;
  --student-dashboard-widget-default-border-bottom-right-radius: 5px;
  --student-dashboard-widget-default-border-top-left-radius: 5px;
  --student-dashboard-widget-default-border-top-right-radius: 5px;
  --student-dashboard-widget-default-border-top: 13px solid;
  --student-dashboard-widget-default-color: rgba(0, 0, 0, 0.87);
  --student-dashboard-widget-progress-border-top-color: #ad1027;
  --student-dashboard-widget-progress-button-bg-color-hover: #c4122c;
  --student-dashboard-widget-progress-button-bg-color: #ad1027;
  --student-dashboard-widget-progress-button-border-radius: var(--student-border-radius);
  --student-dashboard-widget-progress-button-color-hover: #fff;
  --student-dashboard-widget-progress-button-color: #fff;
  --student-dashboard-widget-progress-button-font-size: 11px;
  --student-dashboard-widget-progress-button-font-weight: bold;
  --student-dashboard-widget-progress-button-height: 26px;
  --student-dashboard-widget-progress-button-margin: 7px auto 20px;
  --student-dashboard-widget-progress-button-padding: 6.5px 10.5px 4.5px;
  --student-dashboard-widget-progress-button-width: 105px;
  --student-dashboard-widget-progress-content-bg-color: #fff;
  --student-dashboard-widget-progress-display: block;
  --student-dashboard-widget-progress-title-bg-color: #fff;
  --student-dashboard-widget-progress-title-color: var(--student-dashboard-widget-default-color);
  --student-dashboard-widget-top-label-default-content-font-color: rgba(0, 0, 0, 0.87);
  --student-dashboard-widget-top-label-default-title-font-color: rgba(0, 0, 0, 0.87);
  --student-dashboard-widget-top-label-font-size: 22px;
  --student-dashboard-widget-trophy-banner-font-size: var(--student-progress-widget-trophy-banner-font-size);
  --student-dashboard-widget-trophy-range-font-size: var(--student-progress-widget-trophy-range-font-size);
  --student-dashboard-widget-trophy-range-font-weight: normal;
  --student-nav-logo-background: var(--nav-logo-background);
  --student-nav-logo-height: var(--nav-logo-height);
  --student-nav-logo-margin: var(--nav-logo-margin);
  --student-nav-logo-width: var(--nav-logo-width);
  --student-nav-separator-background: var(--nav-separator-background);
  --student-nav-separator-box-shadow: none;
  --student-nav-separator-dashboard-height: var(--student-nav-separator-height);
  --student-nav-separator-height: var(--nav-separator-height);
  --student-primary-button-min-height: auto;
  --student-progress-display-achievement-column: none;
  --student-progress-display-trophy-column: none;
  --student-progress-display-unit-section-rows: table-row;
  --student-progress-header-color: #f3f3f3;
  --student-progress-performance-default-font-color: #fff;
  --student-progress-summary-default-font-color: #000;
  --student-progress-summary-legend-high-color: #e0efd1;
  --student-progress-summary-legend-high-count-color: var(--theme-font-color);
  --student-progress-summary-legend-item-font-size: 12px;
  --student-progress-summary-legend-low-color: #f2c6c7;
  --student-progress-summary-legend-low-count-color: var(--theme-font-color);
  --student-progress-summary-legend-medium-color: #fadfaa;
  --student-progress-summary-legend-medium-count-color: var(--theme-font-color);
  --student-progress-summary-range-label-font-weight: 600;
  --student-progress-table-border-bottom: inherit;
  --student-progress-table-data-chevron-color: inherit;
  --student-progress-table-data-chevron-font-size: inherit;
  --student-progress-table-data-content-font-size: var(--theme-font-size);
  --student-progress-table-data-content-height: auto;
  --student-progress-table-data-score-font-size: 16px;
  --student-progress-table-header-font-size: var(--theme-font-size);
  --student-progress-table-header-height: auto;
  --student-progress-view-header-background-mix-blend-mode: normal;
  --student-progress-view-header-background: none;
  --student-progress-view-header-justify-content: center;
  --student-progress-view-header-padding: 0 0 0 14px;
  --student-progress-view-max-width: 1260px;
  --student-progress-view-widget-course-border-radius: var(--student-progress-view-widget-default-border-radius);
  --student-progress-view-widget-default-border-radius: 15px;
  --student-progress-view-widget-progress-border-radius: var(--student-progress-view-widget-default-border-radius);
  --student-progress-view-widget-trophies-border-radius: var(--student-progress-view-widget-default-border-radius);
  --student-progress-widget-course-bar-active-background-color: #006e9f;
  --student-progress-widget-course-bar-border-radius: 5px;
  --student-progress-widget-course-bar-height: 20px;
  --student-progress-widget-course-bar-inactive-background-color: #dadadb;
  --student-progress-widget-course-bar-padding: 14px 0 6px 0;
  --student-progress-widget-course-border-top-color: inherit;
  --student-progress-widget-course-border-top-radius: var(--student-progress-view-widget-course-border-radius);
  --student-progress-widget-course-border-top: inherit;
  --student-progress-widget-course-padding: 20px 0 14px 20px;
  --student-progress-widget-course-subtitle-font-size: 16px;
  --student-progress-widget-course-subtitle-font-weight: bold;
  --student-progress-widget-course-title-font-size: 16px;
  --student-progress-widget-course-title-font-weight: 600;
  --student-progress-widget-top-label-font-size: 18px;
  --student-progress-widget-trophies-border-top-color: inherit;
  --student-progress-widget-trophies-border-top-radius: var(--student-progress-view-widget-trophies-border-radius);
  --student-progress-widget-trophies-border-top: inherit;
  --student-progress-widget-trophies-padding: 20px 0 14px 20px;
  --student-progress-widget-trophy-banner-font-size: 18px;
  --student-progress-widget-trophy-banner-line-height: 1.6;
  --student-progress-widget-trophy-range-font-size: 16px;
  --system-notification-card-body-attachment-font-color: #3a9cfa;
  --system-notification-card-body-attachment-font-size: 13px;
  --system-notification-card-body-attachment-font-weight: normal;
  --system-notification-card-body-message-font-color: #000;
  --system-notification-card-body-message-font-size: 13px;
  --system-notification-card-body-message-font-weight: bold;
  --system-notification-card-header-datetime-font-color: #000;
  --system-notification-card-header-datetime-font-size: 13px;
  --system-notification-card-header-datetime-font-weight: normal;
  --system-notification-card-header-line-height: unset;
  --system-notification-card-header-user-font-color: #3a9cfa;
  --system-notification-card-header-user-font-size: 13px;
  --system-notification-card-header-user-font-weight: normal;
  --teacher-library-resource-details-modal-body-font-size: 14px;
  --teacher-library-resource-details-modal-body-loading-padding: 22px 20px 22px 25px;
  --teacher-library-resource-details-modal-body-padding: 22px 20px 22px 45px;
  --theme-background-attachment-login: var(--theme-background-attachment);
  --theme-background-attachment-student: var(--theme-background-attachment);
  --theme-background-attachment: scroll;
  --theme-background-color-login: var(--theme-background-color);
  --theme-background-color-modal-fullscreen: #fff;
  --theme-background-color-student: var(--theme-background-color);
  --theme-background-color: white;
  --theme-background-header-color: #494949;
  --theme-background-image-login: inherit;
  --theme-background-image-student: inherit;
  --theme-background-image: inherit;
  --theme-background-position-login: initial;
  --theme-background-position-student: initial;
  --theme-background-position: initial;
  --theme-background-repeat-login: initial;
  --theme-background-repeat-student: initial;
  --theme-background-repeat: initial;
  --theme-background-size-login: cover;
  --theme-background-size-student: cover;
  --theme-background-size: cover;
  --theme-banner-bg-color-modal-student: #fff;
  --theme-banner-bg-color-modal: var(--theme-banner-color);
  --theme-banner-color: #c6538c;
  --theme-banner-font-color-modal-student: #000;
  --theme-banner-font-color-modal: #000;
  --theme-basic-button-border-color: var(--theme-basic-button-color);
  --theme-basic-button-border-hover-color: var(--theme-basic-button-hover-color);
  --theme-basic-button-color: var(--theme-primary-button-bg-color);
  --theme-basic-button-hover-color: var(--theme-primary-button-hover-color);
  --theme-border-color: var(--theme-dark-border-color);
  --theme-bright-green: #3cb24a;
  --theme-bright-orange: #edab2f;
  --theme-bright-red: #cd373d;
  --theme-button-border-radius-student: var(--theme-button-border-radius);
  --theme-button-border-radius: var(--border-radius);
  --theme-button-default-border-size: 2px;
  --theme-button-default-font-weight-student: var(--theme-button-default-font-weight);
  --theme-button-default-font-weight: bold;
  --theme-button-default-padding-student: 0.6em 1.7em;
  --theme-button-default-padding: 0.6em 1.7em;
  --theme-button-radius: 4px;
  --theme-classroom-dropdown-selection-font-size: var(--theme-tertiary-font-size);
  --theme-dark-border-color: #707070;
  --theme-dark-font-color: black;
  --theme-element-color-login: #f2f2f2;
  --theme-element-color: #f2f2f2;
  --theme-font-color-directly-on-background-student: var(--theme-font-color-directly-on-background);
  --theme-font-color-directly-on-background: rgba(0, 0, 0, 0.87);
  --theme-font-color: rgba(0, 0, 0, 0.87);
  --theme-font-family-bold: var(--theme-font-family);
  --theme-font-family: 'Avenir';
  --theme-font-size-student: var(--theme-font-size);
  --theme-font-size: 14px;
  --theme-header-bg-color-login: var(--theme-tertiary-banner-color);
  --theme-header-color-login: var(--theme-header-color);
  --theme-header-color: white;
  --theme-header-font-size-login: inherit;
  --theme-header-font-size: inherit;
  --theme-header-font-weight-login: inherit;
  --theme-header-height-login: 50px;
  --theme-header-text-align-items: inherit;
  --theme-header-text-display: inline-block;
  --theme-header-text-height: fit-content;
  --theme-header-text-justify-content: inherit;
  --theme-header-text-line-height-login: var(--theme-header-text-line-height);
  --theme-header-text-line-height: 1.36;
  --theme-header-text-width: 246px;
  --theme-header-title-color: var(--theme-font-color-directly-on-background);
  --theme-icon-color: var(--theme-icon-filter-color);
  --theme-icon-filter-color: none;
  --theme-light-font-color: rgba(0, 0, 0, 0.4);
  --theme-light-green: #eef4e9;
  --theme-light-orange: #efe0be;
  --theme-light-red: #f0d3d4;
  --theme-link-color-directly-on-background-hover-student: var(--theme-link-color-hover);
  --theme-link-color-directly-on-background-hover: var(--theme-link-color-hover);
  --theme-link-color-directly-on-background-student: var(--theme-link-color-directly-on-background);
  --theme-link-color-directly-on-background: var(--theme-link-color);
  --theme-link-color-hover: #49a3fa;
  --theme-link-color: #3a9cfa;
  --theme-link-font-size: var(--theme-font-size);
  --theme-link-font-weight: 600;
  --theme-loader-border: rgba(0, 0, 0, 0.1);
  --theme-loader-color: #767676;
  --theme-mini-button-default-padding: var(--theme-button-default-padding);
  --theme-primary-banner-button-bg-color: var(--theme-primary-button-bg-color);
  --theme-primary-banner-button-bg-disabled-color: var(--theme-primary-button-bg-disabled-color);
  --theme-primary-banner-button-border-radius: var(--border-radius);
  --theme-primary-banner-button-border: inherit;
  --theme-primary-banner-button-color: var(--theme-primary-button-color);
  --theme-primary-banner-button-font-size: var(--theme-font-size);
  --theme-primary-banner-button-font-weight: 600;
  --theme-primary-banner-button-hover-color: var(--theme-primary-button-hover-color);
  --theme-primary-banner-button-max-height: auto;
  --theme-primary-banner-button-min-height: auto;
  --theme-primary-button-bg-color-directly-on-background: #2185d0;
  --theme-primary-button-bg-color: #2185d0;
  --theme-primary-button-bg-color2: var(--theme-primary-button-color);
  --theme-primary-button-bg-disabled-color: #bcbcbc;
  --theme-primary-button-border-color2: var(--theme-primary-button-bg-color);
  --theme-primary-button-border-disabled-color: var(--theme-primary-button-bg-disabled-color);
  --theme-primary-button-color-directly-on-background: white;
  --theme-primary-button-color-sso: #fff;
  --theme-primary-button-color: white;
  --theme-primary-button-color2: var(--theme-primary-button-bg-color);
  --theme-primary-button-foreground-disabled-color: #f9f9f9;
  --theme-primary-button-hover-color-directly-on-background: #1d77bb;
  --theme-primary-button-hover-color: #1d77bb;
  --theme-primary-button-max-height: auto;
  --theme-primary-button-min-height: auto;
  --theme-primary-nav-button-bg-color: var(--theme-primary-button-bg-color);
  --theme-primary-nav-button-color: var(--theme-primary-button-color);
  --theme-row-color: #fafafb;
  --theme-secondary-background-color: var(--theme-background-color);
  --theme-secondary-banner-color: var(--theme-banner-color);
  --theme-secondary-border-color: var(--theme-dark-border-color);
  --theme-secondary-button-bg-color: var(--theme-primary-button-bg-color);
  --theme-secondary-button-border-radius: var(--border-radius);
  --theme-secondary-button-color: var(--theme-primary-button-color);
  --theme-secondary-button-hover-color: var(--theme-primary-button-hover-color);
  --theme-secondary-font-color: #3a9cfa;
  --theme-secondary-font-size: 14px;
  --theme-secondary-link-color-hover: var(--theme-link-color-hover);
  --theme-secondary-link-color: var(--theme-link-color);
  --theme-secondary-nav-button-bg-color: var(--theme-primary-nav-button-bg-color);
  --theme-secondary-nav-button-color: var(--theme-primary-nav-button-color);
  --theme-secondary-top-nav-banner-bg-color: var(--theme-secondary-banner-color);
  --theme-secondary-top-nav-bg-color: transparent;
  --theme-secondary-top-nav-box-shadow: none;
  --theme-secondary-top-nav-color: var(--theme-top-nav-color);
  --theme-secondary-top-nav-font-size: var(--theme-secondary-font-size);
  --theme-secondary-top-nav-font-weight: bold;
  --theme-secondary-top-nav-join-class-button-padding: 0.6em 1.2em;
  --theme-secondary-top-nav-selected-bg-color: var(--theme-top-nav-selected-bg-color);
  --theme-secondary-top-nav-selected-color: var(--theme-top-nav-selected-color);
  --theme-secondary-top-nav-selected-font-size: var(--theme-secondary-top-nav-font-size);
  --theme-secondary-top-nav-selected-font-weight: var(--theme-secondary-top-nav-font-weight);
  --theme-student-carousel: #f7f0f1;
  --theme-table-header-color: #f9fafb;
  --theme-tertiary-banner-color: var(--theme-secondary-banner-color);
  --theme-tertiary-border-color: var(--theme-secondary-border-color);
  --theme-tertiary-font-color: white;
  --theme-tertiary-font-size: 11px;
  --theme-top-nav-banner-bg-color: var(--theme-banner-color);
  --theme-top-nav-bg-color: transparent;
  --theme-top-nav-box-shadow: none;
  --theme-top-nav-color: rgba(255, 255, 255, 0.87);
  --theme-top-nav-dropdown-hover-text-decoration: var(--theme-top-nav-hover-text-decoration);
  --theme-top-nav-dropdown-hover-text-underline-offset: var(--theme-top-nav-hover-text-underline-offset);
  --theme-top-nav-font-size: 18px;
  --theme-top-nav-font-weight: bold;
  --theme-top-nav-hover-text-decoration: inherit;
  --theme-top-nav-hover-text-underline-offset: inherit;
  --theme-top-nav-selected-bg-color: var(--theme-primary-nav-button-bg-color);
  --theme-top-nav-selected-color: #e3f30e;
  --theme-top-nav-selected-text-decoration: inherit;
  --theme-top-nav-selected-text-underline-offset: inherit;
  --theme-top-nav-text-transform-student: none;
  --theme-top-nav-text-transform: none;
  --toggler-active-or-inactive-checked-left: 52px;
  --toggler-active-or-inactive-checked-padding: 2px 0 0 12px;
  --toggler-active-or-inactive-content-active: 'Active';
  --toggler-active-or-inactive-content-inactive: 'Inactive';
  --toggler-active-or-inactive-padding: 2px 0 0 22px;
  --toggler-active-or-inactive-width: 72px;
  --toggler-default-check-label-font-color: var(--theme-secondary-font-color);
  --toggler-default-checked-font-size: 11.5px;
  --toggler-default-font-size: 11px;
  --toggler-default-label-font-color-directly-on-background: var(--theme-font-color-directly-on-background);
  --toggler-default-label-font-weight: normal;
  --toggler-directly-on-background-bg-color: var(--theme-primary-button-bg-disabled-color);
  --toggler-gradebook-details-observational-grader-label-font-weight: var(--toggler-gradebook-details-scorable-label-font-weight);
  --toggler-gradebook-details-scorable-label-font-weight: var(--toggler-default-label-font-weight);
  --toggler-hidden-resources-font-weight: var(--toggler-default-label-font-weight);
  --toggler-hide-or-show-checked-left: 42px;
  --toggler-hide-or-show-checked-padding: 2px 0 0 9px;
  --toggler-hide-or-show-padding: 2px 0 0 23px;
  --toggler-hide-or-show-width: 62px;
  --top-nav-active-color: #00FF00;
  --top-nav-banner-color: var(--theme-banner-color);
  --top-nav-dynamic-resource-url-student: none;
  --top-nav-dynamic-resource-url: none;
  --top-nav-notification-bg-color: #fff;
  --top-nav-notification-color: rgba(0, 0, 0, 0.87);
  --view-container-default-width: 1127px;
  --warning-text-color: #ca3b33;
}
